import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import "./header.scss";

const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  console.log(event.currentTarget); // alerts BUTTON
};

const Header = () => {
  return (
    <div>
      <ul>
        <li>
          <Link to="me">Who is this</Link>
        </li>
        <li>
          <Link to="news">News</Link>
        </li>
        <li>
          <Link to="work">Work</Link>
        </li>
        <li>
          <Link to="crypto">Crypto</Link>
        </li>
        <li>
          <Link to="synths">Synths</Link>
        </li>
        <li>
          <Link to="music">music</Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
