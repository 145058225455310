import * as React from "react";
import "./news.scss";
import { Link } from "react-router-dom";

const items = [
  {
    title: "data insight",
    date: "21 nov 2019",
    description:
      "Now you can see how much I loose on the market. see crypto section",
    url: "/crypto",
    images: ""
  },
  {
    title: "mongo",
    date: "20 nov 2019",
    description:
      "I just setup an mongo db somewhere, using REST to retrieve data from kraken with my ancient cheapass webserver, and pushed it through php to mongo",
    url: "",
    images: ""
  },
  {
    title: "Kraken api",
    date: "15 nov 2019",
    description:
      "today I learned how to connect my ancient webserver to kraken rest",
    url: "https://www.kraken.com/features/api",
    images: ""
  },
  {
    title: "react hooks",
    date: "12 nov 2019",
    description: "today I learned about hooks",
    url: "",
    images: ""
  },
  {
    title: "tsx",
    date: "5 nov 2019",
    description: "today I learned about tsx",
    url: "",
    images: ""
  },
  {
    title: "New track released",
    date: "3 nov 2019",
    description: "today I learned about hooks",
    url: "",
    images: ""
  }
];

const News = () => {
  return (
    <div>
      news
      {items.map(item => {
        // work component
        return (
          <div>
            ({item.title} {item.date} - {item.description} -{" "}
            <Link to={item.url}>{item.url}</Link>)
          </div>
        );
      })}
    </div>
  );
};

export default News;
