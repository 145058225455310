import React from "react";
import { useState, useEffect } from "react";

import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Me from "./components/pages/Me";
import News from "./components/pages/News";
import Work from "./components/pages/Work";
import Crypto from "./components/pages/Crypto";
import Music from "./components/pages/Music";
import Synths from "./components/pages/Synths";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

// crypto news
// synths news
// projects
// wintersport news
// holidays
// instagram
// info
// https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually

function App() {
  useEffect(() => {});

  return (
    <div className="App">
      non functional site.
      <Router>
        <Header></Header>
        <Switch>
          <Route path="/me" component={Me} />
          <Route path="/news" component={News} />
          <Route path="/work" component={Work} />
          <Route path="/crypto" component={Crypto} />
          <Route path="/music" component={Music} />
          <Route path="/synths" component={Synths} />
        </Switch>

        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
