import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Calculations from "./Calculations";
import MyChart from "./Chart";

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import "./news.scss";

const URLENCODED_HEADER = {
  Accept: "application/json",
  "Content-Type": "application/json"
  // "User-Agent": window.navigator.userAgent
};

const headers = new Headers();

const Crypto = () => {
  const [result, setResult] = useState({
    status: "loading",
    payload: {}
  });

  useEffect(() => {
    // https://www.erikie.nl/retrieve.php
    fetch("https://erikie.nl/retrieve.php", {
      method: "GET"
      // cache: "no-cache",
      // headers: {
      //   "Content-Type": "application/json"
      //   // 'Content-Type': 'application/x-www-form-urlencoded',
      // }
    })
      .then(response => response.json())
      .then(response => {
        setResult({ status: "loaded", payload: response });
      })
      .catch(error => {
        setResult({ status: "error", payload: error });
        console.log(error);
      });
  }, []);

  let { id } = useParams();
  return (
    <div>
      crypto - do I feel sad today? - yes {result.status}
      {result.status === "loaded" && (
        <div>
          <div>{Calculations(result.payload, "current")}</div>
          <MyChart payload={Calculations(result.payload, "history")}></MyChart>
        </div>
      )}
    </div>
  );
};

export default Crypto;
