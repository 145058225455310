import * as React from "react";

let wallet: any = [];
let market: any = [];

const Calculations = (payload: any, type: string) => {
  wallet = payload.wallet;
  market = payload.markethistory;

  console.log(new Date().getTime());
  if (type === "current") {
    const g = getCurrentValue();
    console.log(new Date().getTime());
    return <div>€ {g}</div>;
  }
  if (type === "history") {
    const g = getHistoryValues();
    console.log(new Date().getTime());
    return g;
  }
};

const getHistoryValues = (): any => {
  let j = -1;
  const v = Object.entries(market).map(val => {
    j++;
    return [j, calculateValue(wallet[j], market[j])];
  });

  return { wealthHistory: v, portfolioHistory: getHistoryWallet() };
};

const getHistoryWallet = (): any => {
  const walletHistory: any = {};
  Object.entries(wallet).map(entry => {
    const id: number = parseInt(entry[0]);
    const crncy: any = entry[1];
    const marketDetail = market.filter(
      (obj: any) => obj.date.unixtime === crncy.date.unixtime
    )[0];

    marketDetail["ZEUR"] = 1;

    Object.entries(crncy).map(currency => {
      const valuta: string = currency[0];
      const value: any = currency[1];

      if (valuta !== "KFEE" && valuta !== "_id" && valuta !== "date") {
        const worth =
          (marketDetail[valuta + "EUR"] ||
            marketDetail[valuta + "ZEUR"] ||
            marketDetail["ZEUR"] ||
            0) * value;

        if (!walletHistory[valuta]) walletHistory[valuta] = [];

        const previousItem =
          walletHistory[valuta][walletHistory[valuta].length - 1];

        if (previousItem) {
          if (previousItem[1] !== worth) {
            if (valuta === "ZEUR")
              if (previousItem[1] === 0)
                walletHistory[valuta].push([id - 1, 0.1]);
            // console.log(walletHistory);

            walletHistory[valuta].push([id, worth]);
          }
        } else {
          walletHistory[valuta].push([id, worth]);
        }
      }
    });
  });

  return walletHistory;
};

const getCurrentValue = (): any => {
  return calculateValue(wallet[wallet.length - 1], market[market.length - 1]);
};

const calculateValue = (walletItem: object, marketItem: any): number => {
  let total = 0;

  marketItem["ZEUR"] = 1;

  Object.entries(walletItem).map(entry => {
    const valuta: string = entry[0];
    const value: any = entry[1];
    if (
      valuta !== "KFEE" &&
      valuta !== "_id" &&
      valuta !== "date" &&
      value !== 0
    ) {
      total +=
        (marketItem[valuta + "EUR"] ||
          marketItem[valuta + "ZEUR"] ||
          marketItem["ZEUR"] ||
          0) * value;
    }
  });
  return total;
};

export default Calculations;
