import React from "react";

//@ts-ignore
import { Chart } from "react-charts";

const MyChart = (payload: any) => {
  console.log(new Date().getTime());
  const summon = Object.entries(payload.payload.portfolioHistory).map(v => {
    return v[1];
  });

  console.log(new Date().getTime());
  summon.push(payload.payload.wealthHistory);

  console.log(new Date().getTime());

  const data = React.useMemo(() => summon, []);

  const series = React.useMemo(
    () => ({
      type: "line",
      showPoints: false
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" }
    ],
    []
  );

  const lineChart = (
    // A react-chart hyper-responsively and continuusly fills the available
    // space of its parent element automatically
    <div
      style={{
        width: "100vw",
        height: "100vh"
      }}
    >
      <Chart data={data} axes={axes} />
    </div>
  );

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: "0",
        zIndex: -1
      }}
    >
      <Chart data={data} axes={axes} series={series} />
    </div>
  );
};

export default MyChart;
