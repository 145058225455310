import * as React from "react";
import "./news.scss";

const items = [
  {
    title: "Nationale Nederlanden",
    subtitle: "native Mobile App (IOS)",
    description: "wow",
    period: "2014-2018"
  },
  {
    title: "KNMP",
    subtitle: "native Mobile App (IOS)",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "Sense.info | soaaids",
    subtitle: "Webbased VR",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "Peugeot",
    subtitle: "VR interactive installation | game",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "Vattenfall",
    subtitle: "PWA realtime interactive incident map",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "Vrumona",
    subtitle: "Dynamics cocktail configurator",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "FWU",
    subtitle: "PWA Mobile animation",
    description: "woot",
    period: "2014-2018"
  },
  {
    title: "Teeez makeup artist",
    subtitle: "Instore app face projection",
    description: "woot",
    period: "2014-2018"
  }
];

const Work = () => {
  return (
    <div>
      work
      {items.map(item => {
        // work component
        return (
          <div>
            ({item.title} - {item.subtitle})
          </div>
        );
      })}
    </div>
  );
};

export default Work;
